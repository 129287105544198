<template>
  <div class="row">
    <div class="col-xl-2 col-md-2">
      <div class="mb-4 w-100">
        <select class="custom-select m-auto " style="max-width: 94%;" v-model="filtre_level">
          <option value="" disabled selected>{{ $t('competition.level.text') }}</option>
          <option value="easy">{{ $t('competition.level.easy') }}</option>
          <option value="medium">{{ $t('competition.level.medium') }}</option>
          <option value="hard">{{ $t('competition.level.hard') }}</option>
          <option value="">{{ $t('competition.all') }}</option>
        </select>
      </div>
      <div class="mb-4 w-100">
        <select class="custom-select m-auto " style="max-width: 94%;" v-model="filtre_state">
          <option value="" disabled selected>{{ $t('competition.state.text') }}</option>
          <option value="not read">{{ $t('competition.state.unread') }}</option>
          <option value="read">{{ $t('competition.state.read') }}</option>
          <option value="solved">{{ $t('competition.state.solved') }}</option>
          <option value="">{{ $t('competition.all') }}</option>

        </select>
      </div>
      <div class="mb-4 w-100">
        <select class="custom-select m-auto " style="max-width: 94%;" v-model="filtre_type">
          <option value="" disabled selected>{{ $t('competition.type.text') }}</option>
          <option value="static">{{ $t('competition.type.static') }}</option>
          <option value="dynamic">{{ $t('competition.type.dynamic') }}</option>
          <option value="manual">{{ $t('competition.type.manual') }}</option>
          <option value="">{{ $t('competition.all') }}</option>

        </select>
      </div>
      <simplebar class="cat" style="padding-right: 12px" data-simplebar-auto-hide="false" v-if="!load">

        <div >
          <b-skeleton class="mb-2 w-100" type="button"></b-skeleton>
          <b-skeleton class="mb-2 w-100" type="button"></b-skeleton>
          <b-skeleton class="mb-2 w-100" type="button"></b-skeleton>
          <b-skeleton class="mb-2 w-100" type="button"></b-skeleton>
        </div>


      </simplebar>
      <simplebar class="cat" style="padding-right: 12px" data-simplebar-auto-hide="false"  v-else>
          <div class="card task-box  mr-4 w-100" v-for="(cat,index) in categories" v-bind:key="cat.id"
               :class="{ 'active' : category_active == index }" @click="category_active = index" >
            <button class="btn  m-auto w-100">
              {{ cat.name }}
            </button>
          </div>
      </simplebar>

    </div>
    <div class="col-xl-10 col-md-10">
      <b-card-text>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-md-4">
                <div class="row">

                  <div class="col-12 pr-4 pl-4">
                    <div class="page-title-box d-flex  text-dark  justify-content-between">
                                                        <span style="cursor: pointer"
                                                              @click="(sort_solves == 1)?sort_solves=0:sort_solves=1"> <i
                                                            class="fas fa-sort mr-2 font-size-14"></i>{{
                                                            $t('competition.solves')
                                                          }}</span>
                      <div class="page-title-right" style="cursor: pointer"
                           @click="sort_scores = ! sort_scores">
                        {{ $t('competition.score') }} <i
                          class="fas fa-sort  ml-2 font-size-14"></i>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->
                </div>
                <simplebar class="chall" style="padding-right: 12px;padding-left: 12px"
                           data-simplebar-auto-hide="false" v-if="!load">
                <div class="list-group" group="tasks" >
                  <div v-for="n in 3" :key="n">
                    <div class="card task-box challenge card-body">
                      <div>
                        <h5 class="font-size-15">
                          <b-skeleton></b-skeleton>
                        </h5>
                      </div>
                      <div class="row">
                        <div class="col-xl-6">
                          <b-skeleton class="mr-2 mb-1"></b-skeleton>
                          <b-skeleton class="mr-2 mb-1"></b-skeleton>
                        </div>
                        <div class="col-xl-6">
                          <b-skeleton class="mr-2 mb-1"></b-skeleton>
                          <b-skeleton class="mr-2 mb-1"></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                </simplebar>
                <simplebar class="chall" style="padding-right: 12px;padding-left: 12px"
                           data-simplebar-auto-hide="false" v-else>
                  <div v-for="chall in challenges" :key="chall.id"
                       class="card task-box challenge" @click="switchChall(chall)"
                       :class="{ 'active_chall' : challSelected.id == chall.id }">
                    <div class="card-body">
                      <div class="float-right ml-1">
                        <i class="font-size-12 " :class=" {
                                'text-info fas fa-bookmark': (`${chall.id}` == challSelected.id &&  challSelected.state == 'read') || ( `${chall.state}` == 'read'),
                                'text-success fas fa-check': (`${chall.id}` == challSelected.id &&  challSelected.state == 'solved') || (`${chall.state}` == 'solved') }"></i>
                      </div>
                      <div>
                        <h6>{{ chall.name }}</h6>
                      </div>
                      <div class=" float-left">
                        <h5 class="font-size-12 mb-1">
                          {{ chall.solves }}</h5>
                        <p class="mb-0 font-size-12 text-muted">
                          {{ $t('competition.solves') }}</p>

                      </div>
                      <div class="text-right">
                        <h5 class="font-size-12  mb-1">{{ chall.score }}
                          pts</h5>
                        <p class="mb-0 font-size-12 text-muted">{{ chall.level }}</p>
                      </div>
                    </div>
                  </div>

                </simplebar>
              </div>
              <div class="col-xl-8 col-md-8" style="min-height: 65vh!important;">
                <div class="row" v-if="load">
                  <div class="col-xl-12" v-if="type=='training'">
                    <div class="input-group ">
                      <a href="#" class="text-dark d-flex align-items-center ml-auto"
                         v-b-modal.modal-stream
                      ><i class="bx bx-video-recording h5 my-0 p-1 mr-2 "></i>
                        <span>{{$t('competition.stream')}}</span> </a>
                    </div>
                    <new-stream/>
                  </div>
                  <div class="col-xl-12 text-right mt-2">
                    <button class="btn btn-danger mr-3 mb-2 " :disabled="user_address" @click="connectMetamask"
                            v-if="is_blockchain">
                      {{ user_address ? user_address.slice(0, 4) + '....' + user_address.slice(-2) : 'Connect' }}
                    </button>
                    <button v-b-modal.modal-resources
                            class="btn btn-success mr-3 mb-2 ">
                      {{ $t('competition.resources') }}
                    </button>
                    <resources :rsc="details[category_active].detail.tools"/>
                    <button v-b-modal.modal-about
                            class="btn btn-success  mb-2 ">
                      {{ $t('competition.about_cat') }}
                    </button>
                    <about-category :about="details[category_active].detail"/>
                  </div>
                </div>
                <div v-else class="row mx-0 mb-3 justify-content-end">
                  <b-skeleton type="button" width="15%" class="mx-2"></b-skeleton>
                  <b-skeleton type="button"  width="15%" class="mx-2"></b-skeleton>
                </div>
                <div class="card task-box" style="min-height: 50vh!important;">

                  <div class="card-body" v-if="load">
                    <div class="float-right ml-1">
                                        <span class="mr-2">
                                            <span class="bx bx-star text-warning ml-1"></span>
                                            <span class="bx bx-star ml-1" :class="{
                                                            'text-warning': `${challSelected.level}` == 'medium' || `${challSelected.level}` == 'hard' }"></span>
                                            <span class="bx bx-star ml-1" :class="{
                                                            'text-warning': `${challSelected.level}` == 'hard'}"></span>
                                        </span>
                      <span
                          class="badge badge-pill font-size-12 badge-soft-primary mr-2">{{
                          challSelected.type
                        }}</span>
                      <i class="text-warning  font-size-18"
                         :class="(`${challSelected.state}` == 'solved')?'fas fa-check':'fas fa-bookmark'"></i>

                    </div>
                    <div class="mb-4">
                      <h5 class="font-size-15">{{ challSelected.name }}
                      </h5>
                      <p class="mb-2 text-muted"> {{ $t('competition.created_by') }}
                        {{ challSelected.created_by.username }}</p>
                      <p class="mb-0 text-muted">
                        <!--                                                        <span style=" text-decoration: line-through;" v-if="challSelected.first_score">{{challSelected.first_score}}</span>-->
                        {{ challSelected.score }} pts /
                        {{ challSelected.solves }}
                        {{ $t('competition.solves') }}</p>
                    </div>
                    <div>
                      <h6 class=" mb-3">{{ $t('competition.description') }}</h6>
                      <simplebar style="max-height: 300px;padding-right: 8px;padding-left: 8px"
                                 data-simplebar-auto-hide="false">
                        <div class="mb-0 text-muted" v-html="challSelected.description">
                          {{ challSelected.description }}
                        </div>
                      </simplebar>

                    </div>

                    <div class="row">
                      <div class="col-xl-12">
                        <div class=" text-dark  text-center mb-3">
                          <a class="btn btn-primary  mt-3 font-size-13 mr-2 pl-4 pr-4 "
                             v-if="challSelected.attachment"
                             target="_blank" :href="challSelected.attachment">
                            {{ $t('competition.attachement') }}
                          </a>

                          <a class="btn btn-primary  mt-3 font-size-13 pl-4 pr-4 "
                             target="_blank" :href="challSelected.url"
                             v-if="challSelected.url">
                            {{ $t('competition.website') }}
                          </a>

                        </div>
                      </div>
                    </div>


                  </div>
                  <div class="card-body" v-else>
                    <div class="row">
                      <div class="col-xl-6">
                        <b-skeleton class="mb-2" width="85%"></b-skeleton>
                        <b-skeleton class="mb-2" width="50%"></b-skeleton>
                        <b-skeleton width="30%"></b-skeleton>
                      </div>
                      <div class="col-xl-6">
                        <b-skeleton class="ml-auto" width="30%"></b-skeleton>
                      </div>
                    </div>
                    <div>
                      <h6 class=" my-4">{{ $t('competition.description') }}</h6>
                      <simplebar style="max-height: 130px;padding-right: 8px;padding-left: 8px"
                                 data-simplebar-auto-hide="false">
                        <b-skeleton class="mb-1"></b-skeleton>
                        <b-skeleton class="mb-1"></b-skeleton>
                        <b-skeleton class="mb-1"></b-skeleton>
                        <b-skeleton class="mb-1" width="90%"></b-skeleton>
                        <b-skeleton class="mb-1" width="85%"></b-skeleton>
                        <b-skeleton class="mb-4" width="50%"></b-skeleton>
                      </simplebar>

                    </div>
                  </div>
                </div>
                <div class="row submit_div my-2" >
                  <b-input-group class="col-xl-8 col-lg-8 col-md-12 mb-2 pr-0"
                                 v-if="!is_blockchain">
                    <template #prepend style="height: 35px">
                      <b-input-group-text>
                        <i class="fa fa-flag mr-1" aria-hidden="true"></i>
                      </b-input-group-text>
                    </template>
                    <b-form-input type="text" class="form-control"
                                  placeholder="CRISI5{THIS_IS_FLAG}"
                                  v-model="submit_flag" @keyup.enter="submit"
                                  :class="{ 'is-invalid': typesubmit && $v.submit_flag.$error }"
                                  :disabled="challSelected.state == 'solved' || state == 'writup'">
                    </b-form-input>
                    <div v-if="typesubmit && $v.submit_flag.$error"
                         class="invalid-feedback">
                      <span v-if="!$v.submit_flag.required"> {{ $t('validation.required') }}</span><br
                        v-if="!$v.submit_flag.required"/>
                      <span v-if="!$v.submit_flag.valid">{{ $t('validation.invalid_flag') }}</span>
                    </div>
                  </b-input-group>
                  <div class="col-xl-8 col-lg-8 col-md-12 mb-2 pr-0"
                       v-else-if="user_address">
                    <button class="btn btn-primary btn-sm mb-2 mr-xl-3 " style="height: 35px"
                            @click="createInstance"
                            :disabled="(challSelected.state == 'solved') || is_submit || is_request">
                      {{ is_request ? 'Pending Transaction ....' : ' Create New Instance' }}
                    </button>
                    <button class="btn btn-dark btn-sm mb-2 " style="height: 35px"
                            @click="submitInstance"
                            v-if="instance_address!='0x0000000000000000000000000000000000000000' && state != 'writup'"
                            :disabled="( challSelected.state == 'solved') || is_submit">
                      {{
                        is_submit ? $t('competition.submit') + 'ing...' : $t('competition.submit') + ' Instance'
                      }}
                    </button>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 mb-2 ml-auto pl-0">

                    <button class="btn btn-sm btn-success  submit " style="height: 35px; min-width: 90px"
                            @click="submit" v-if="!is_blockchain"
                            :disabled="(challSelected.state == 'solved') || state == 'writup' || is_submit">
                            <i class="fa fa-flag mr-1" aria-hidden="true"></i>
                      {{ is_submit ? $t('competition.submit') + 'ing...' : $t('competition.submit') }}
                    </button>
                    <share-social-media  :t="type === 'training'" :challenge="challSelected"
                                        :category="categories[category_active].name"/>

                    <button v-b-modal.modal-report class="btn btn-secondary float-right btn-sm"
                            style="height: 35px">
                      <i class="fa fa-bug mr-1" aria-hidden="true"></i> {{ $t('competition.report') }}
                    </button>
                    <report :challenge_id="challSelected.id" :type="type"/>
                  </div>


                </div>
                <a :href="'https://testnet.snowtrace.io/address/'+instance_address" target="_blank"
                   class="text-center d-flex justify-content-center text-success"
                   v-if="instance_address!='0x0000000000000000000000000000000000000000' && is_blockchain">
                  Instance address : {{ instance_address }}
                </a>
              </div>
            </div>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </b-card-text>
    </div>
    <is-premium v-if="type=='training'"/>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import Report from "@/components/popups/report";
import helper from "@/assets/helpers/helper";
import ShareSocialMedia from "@/components/popups/shareSocialMedia";
import {required} from "vuelidate/lib/validators";
import {Web3Helper} from "@/assets/helpers/Web3Helper";
import Resources from "@/components/popups/resources";
import AboutCategory from "@/components/popups/about_cat";
import Vue from "vue";
import NewStream from "@/components/popups/newStream";
import IsPremium from "./popups/isPremium";

export default {
  name: "Challenges",
  components: {
    IsPremium,NewStream,
    AboutCategory,
    Resources, ShareSocialMedia, Report, simplebar
  },
  props: ['type','state'],
  data() {
    return {
      challSelected: {
        name: '',
        created_by: {},
        solves: 0,
        state: 'not read'
      },
      details: [
        {
          challenges: [],
          detail: {}
        }
      ],
      categories:[
        {name:''}
      ],
      filtre_level: '',
      filtre_state: '',
      filtre_type: '',
      is_submit: false,
      is_request: false,
      typesubmit: false,
      user_address: false,
      load: false,
      signature: '',
      category_active: 0,
      instance_address: '0x0000000000000000000000000000000000000000',
      submit_flag: '',
      sort_solves: 2,//asc
      sort_scores: 0,//asc
    }
  },
  created() {
    this.loadChallenges()
  },
  mounted() {
    if(this.type == 'competition') {
      var channel_competition = this.$store.state.pusher_crisis.subscribe('private-competitions_' + this.$route.params.id);
      var socketId = channel_competition.pusher.connection.socket_id;
      Vue.prototype.$http.defaults.headers.common['X-Socket-Id'] = socketId;
      channel_competition.bind('competition_challengeSolved', (data) => {
        console.log("chall solved : ", data);
        let cat_index = this.details.findIndex(item => item.detail.id === data.challenge.category);
        if (cat_index != -1) {
          let index = this.details[cat_index].challenges.findIndex(item => item.id === data.challenge.id);
          this.details[cat_index].challenges[index].solves++
          if (data.team_id === this.$store.state.team.id) {
            let rank = data.rank
            let score = data.score
            this.details[cat_index].challenges[index].state = 'solved'
            this.$store.commit('updateTeam', {rank, score})
          }
        }

      });
      channel_competition.bind('competition_notification_created', (data) => {
        console.log("new_notif_comp", data)
        this.$emit('new_notif_comp', data)
      });
      channel_competition.bind('competition_notification_updated', (data) => {
        console.log("edit_notif_comp", data)
        this.$emit('edit_notif_comp', data)
        // this.notifications[index]=data
      });
      channel_competition.bind('competition_notification_deleted', (data) => {
        console.log("delete_notif_comp", parseInt(data.id))
        this.$emit('delete_notif_comp', parseInt(data.id))

      });
      channel_competition.bind('refresh_challenges', (data) => {
        console.log("refresh challenges", data)
        this.loadCompetition()
        this.$forceUpdate()
      });
    }
  },

  watch: {

    category_active: async function () {
      if (this.$route.query.challenge_id && this.$route.query.category_id && this.type != 'competition' ) {
        let index = this.challenges.findIndex(item => item.id === this.$route.query.challenge_id);
        if (index >= 0) this.challSelected = this.challenges[index]
      }
      else if (this.challenges.length) this.challSelected = this.challenges[0]

      if (this.is_blockchain) await this.checkUserConnected()
      this.sort_scores = 0
      this.sort_solves = 2
      if (this.type == 'competition' && this.challSelected.state == 'not read') await this.changeState(this.challSelected.id)
      if (this.type == 'training' && this.challSelected.state == 'not read' && !(this.challSelected.premium && this.$store.state.user.is_vip == 0)) this.changeState(this.challSelected.id)

    },
  },
  validations: {
    submit_flag: {
      required,
      valid: function (value) {
        return /^CRISIS{.*}$/.test(value)
      }
    },
  },

  computed: {
    Url: function () {
       if(this.type == 'competition') return 'competitions/' + this.$route.params.id + '/'
       else return ''
    },
    challenges: function () {
      let challs = []
      let challenges = []
      if (this.details.length >= 1) {
        if (this.filtre_level == "" && this.filtre_state == "" && this.filtre_type == "") {
          challs = this.details[this.category_active].challenges
        } else challs = this.filtre_all(this.details[this.category_active].challenges);
      }
      if(this.type == 'competition'){
        challenges = challs.map(p => ({
          ...p,
          score: (p.type == 'dynamic') ? Math.max(p.min_score, p.score - (p.solves * p.decay_limit)) : p.score,
          state:p.state_challenge_competition.length?p.state_challenge_competition[0].state:'not read'
        }));
      }
      else if(this.type == 'training') {
        challenges = challs.map(p => ({
          ...p,
          score: (p.type == 'dynamic') ? Math.max(p.min_score, p.score - (p.solves * p.decay_limit)) : p.score,
          state:p.state.length?p.state[0].state:'not read'
        }));
      }

      return this.sortChallenges(challenges)
    },
    is_blockchain: function () {
      return !this.challSelected.has_flag
    }
  },
  methods: {
    async loadChallenges() {
      let details = []
      if(this.type == 'competition') {
         details = await helper.getAll(this.Url + 'challenges');
        if (!details) await this.$router.push({name: 'ProfilCompetition', params: {id: this.$route.params.id}})
      }
      else  details = await helper.getAll('training');
      if(details) {
        this.details = details.data
        let categories = []
        this.details.forEach(function (item) {
          categories.push(item.detail)
        });
        this.categories = categories
        if (this.$route.query.challenge_id && this.$route.query.category_id && this.type != 'competition' ){
          console.log(this.details)
          console.log(this.$route.query.category_id)
          let index_cat = this.details.findIndex(item => this.$route.query.category_id == item.detail.id );
          console.log(index_cat)
          if(index_cat != -1)  {
            this.category_active = index_cat
            let index = this.challenges.findIndex(item => item.id === this.$route.query.challenge_id);
            if (index >= 0) this.challSelected = this.challenges[index]
          }
          else this.challSelected = this.challenges[0]

        }
        else this.challSelected = this.challenges[0]
        this.load = true
        if (this.is_blockchain) await this.checkUserConnected()
        if (this.type == 'competition' && this.challSelected.state=='not read') await this.changeState(this.challSelected.id)
        if (this.type == 'training' && this.challSelected.state == 'not read' && !(this.challSelected.premium && this.$store.state.user.is_vip == 0)) this.changeState(this.challSelected.id)

      }
    },
    sortChallenges(challs) {
      if (!this.sort_scores) challs.sort((a, b) => parseFloat(a.score) - parseFloat(b.score));
      if (this.sort_scores) challs.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
      if (this.sort_solves == 0) challs.sort((a, b) => parseFloat(a.solves) - parseFloat(b.solves));
      if (this.sort_solves == 1) challs.sort((a, b) => parseFloat(b.solves) - parseFloat(a.solves));
      if(this.type == 'competition') return challs
      else return challs.sort((a, b) => parseFloat(a.premium) - parseFloat(b.premium))
    },
    async checkUserConnected() {

      try {

        await window.ethereum
            .request({method: 'eth_accounts'})
            .then(async (accounts) => {
              if (accounts.length == 0) this.user_address = false;
              else {
                this.user_address = accounts[0]
                this.instance_address = await Web3Helper.getChallengeInstance(this.challSelected.id)
              }
            })

            .catch((err) => {
              console.error(err);
            });
      } catch (e) {
        console.log(e)
        this.user_address = false;
        if(!window.ethereum)  this.$alertify.error("looks like you don't have a wallet yet ");
         else this.$alertify.error(e.message);
      }


    },
    async submitInstance() {
      let signature = await Web3Helper.signMetamask()
      if (signature) {
        this.signature = signature
        await this.submitfunction()
      }
    },
    submit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.submitfunction()
    },
    async submitfunction() {
      let item = {}
      if (this.is_blockchain) item.signature = this.signature
       else item.flag = this.submit_flag
      this.is_submit = true
      let re = await helper.postReturnData(this.Url+ 'challenges/' + this.challSelected.id + '/submit', item)
      this.submit_flag = ''
      this.typesubmit = false
      this.is_submit = false
      if (re) {
        this.solvedChallenge(re)
      }
    },
    solvedChallenge(re) {
      let index = this.details[this.category_active].challenges.findIndex(item => item.id === this.challSelected.id);
      console.log("index = ", index)
      this.details[this.category_active].challenges[index].state = 'solved'
      this.details[this.category_active].challenges[index].solves++
      this.challSelected.solves++
      this.challSelected.state = 'solved'
      if(this.type == 'training') this.$store.commit('incrementScore', this.challSelected.score)
      else {
        let rank = re.rank
        let score = re.score
        this.$store.commit('updateTeam', {rank, score})
      }
      this.$root.$emit('bv::show::modal', 'modal-share-social')
    },

    async switchChall(chall) {
      this.submit_flag = ''
      this.instance_address = '0x0000000000000000000000000000000000000000'
      this.challSelected = chall
      if (this.is_blockchain) await this.checkUserConnected()
      if (this.type == 'competition' && chall.state=='not read') this.changeState(chall.id)
      if(this.type == 'training') {
        if (this.challSelected.premium && this.$store.state.user.is_vip == 0) this.$root.$emit('bv::show::modal', 'modal-premium')
        else if (chall.state=='not read') this.changeState(chall.id)
      }
    },
    async changeState(id) {
      this.challSelected.state = 'read'
      await helper.postData(this.Url+'challenges/' + id + '/read', 's')

    },
    filtre_all(tab) {
      return tab.filter(chall => {
        let type = chall.type.toString();
        let level = chall.level.toString();
        if (this.filtre_state) {
          let  state = chall.state.toString();
          return level.includes(this.filtre_level)
              && type.includes(this.filtre_type)
              && state == this.filtre_state
        } else return level.includes(this.filtre_level)
            && type.includes(this.filtre_type)

      });


    },
    async connectMetamask() {
      this.user_address = await Web3Helper.loginMetamask()
    },
    async createInstance() {
      this.is_request = true
      var vm = this;
      try {
        await Web3Helper.NewChallengeInstance(this.challSelected.id).then(async (result, err) => {
              if (!err) {
                const interval = setInterval(async function () {
                  console.log("Attempting to get transaction receipt...");
                  await Web3Helper.getReceiptTx(result).then(async (data) => {
                    if (data) {
                      vm.is_request = false
                      console.log("contact", data)
                      let decodedParameters = Web3Helper.decodeLogs(data)
                      clearInterval(interval);
                      console.log("ss", decodedParameters.challengeInstanceAddress)
                      vm.instance_address = decodedParameters.challengeInstanceAddress
                    }

                  })
                }, 3000);

              } else vm.is_request = false

            }
        )
      } catch (e) {
        console.log(e)
        this.is_request = false
        this.$alertify.error(e.message);
      }

    },


  }
}
</script>

<style scoped>
.check-nav-icon {
  font-size: 26px !important;
}

.challenge {
  cursor: pointer;
  transition: all .2s ease-in-out;
}


@media screen and (min-width: 900px) {
  .flag {
    width: 80%;
  }

  .cat {
    max-height: 50vh;
  }

  .chall {
    max-height: 60vh;
  }
}

@media screen and (max-width: 900px) {
  .flag {
    width: 100%;
  }

  .cat {
    max-height: 420px;
  }

  .chall {
    max-height: 510px;
  }
}

.challenge:hover, .active_chall {
  transform: scale(1.03);
  background-color: #34c38f;
}

.active_chall i {
  color: #ECBD34 !important;
}

.submit:hover {
  background-color: #34c38f;
  color: white;
}

.active {
  background-color: #556ee6;
}

/deep/ .input-group-prepend {

  height: 36px !important;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
/deep/.ql-syntax::-webkit-scrollbar {
  height: 6px!important;

}
/deep/.ql-syntax  {
  scrollbar-width: thin!important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG) !important;
}
/deep/.ql-syntax ::-webkit-scrollbar-track {
  background: none!important;
}
/deep/div ::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) !important;
  border-radius: 6px!important;
}
</style>
